import { DataObject } from 'o365-dataobject';

import { GeoLocation } from "o365-utils";
import { DataObjectFileUpload } from './DataObject.FileUpload.ts';

declare module './DataObject.FileUpload.ts' {
    interface DataObjectFileUpload {
        enableGeoLocation: DataObjectGeoLocationData;
        _geoLocation: DataObjectGeoLocationData;
    }
}

Object.defineProperty(DataObjectFileUpload.prototype, "enableGeoLocation", {
    /* get: function enableGeoLocation(this: DataObjectFileUpload) {
        if (!this._geoLocation) this._geoLocation = new DataObjectGeoLocationData(this._dataObject);
        return this._geoLocation;
    } */
    get() {
        return () => {
            if (!this._geoLocation) this._geoLocation = new DataObjectGeoLocationData(this._dataObject);
            console.log(this)
        }
    }
});

class DataObjectGeoLocationData {
    private static _exifReader: any;
    private _dataObject: DataObject;


    private _addExifData: boolean = false;
    private _addLocationData: boolean = false;
    private _addCTGeoLocation: boolean = false;

    constructor(pDataObject: DataObject) {
        this._dataObject = pDataObject;

        this._addExifData = this._dataObject.fields.hasOwnProperty("ExifData");
        this._addLocationData = this._dataObject.fields.hasOwnProperty("LocationData");
        this._addCTGeoLocation = this._dataObject.fields.hasOwnProperty("GeoLocation") && this._dataObject.fields.hasOwnProperty("GeoSource");

        this._dataObject.fileUpload.onAsync('BeforeFileUploadAsync', async (vFile, fileData) => {
            await this.initExifReader();

            if (this._addExifData) {
                fileData = Object.assign(fileData, { ExifData: await this.getExifData(vFile) });
            }

            if (this._addLocationData) {
                fileData = Object.assign(fileData, { LocationData: await this.getLocationData() });
            }

            // to support the way CT currently handles geolocation on attachments
            if (this._addCTGeoLocation) {
                // TODO: add exif data here
                const json = await this.getLocationData();
                if (json) {
                    const data = JSON.parse(json);
                    fileData = Object.assign(
                        fileData,
                        {
                            GeoLocation: JSON.stringify({ type: "Point", coordinates: [data.longitude, data.latitude] }),
                            GeoSource: "browser",
                        }
                    );
                }
            }
            
            return fileData
        })
    }

    get addExifData() {
        return this._addExifData;
    }

    get addlocationData() {
        return this._addLocationData;
    }

    get addCTGeoLocation() {
        return this._addCTGeoLocation;
    }

    private async initExifReader() {
        if (DataObjectGeoLocationData._exifReader == null) {
            DataObjectGeoLocationData._exifReader = (await import('exifreader')).default;
        } 
    }

    async getExifData(pFile: File): Promise<string | null> {
        try {
            const exifObj: any = {};
            const tags = await DataObjectGeoLocationData._exifReader.load(pFile);
            console.log(tags);
            const relevantTags = ['GPSLatitude', 'GPSLatitudeRef', 'GPSLongitude', 'GPSLongitudeRef', 'GPSImgDirectionRef', 'GPSAltitudeRef', 'Model', 'DateTime', 'DateTimeDigitized', 'DateTimeOriginal'];
            for (const tagName of relevantTags) {
                if (tags[tagName]?.description) {
                    exifObj[tagName] = tags[tagName]?.description;
                }
            }

            if (Object.keys(exifObj).length) {
                return JSON.stringify(exifObj);
            }
        }
        catch (e) {
            console.warn("unable to fetch exif data", e);
        }

        return null;
    }

    async getLocationData(): Promise<string | null> {
        return new Promise((resolve) => {
            if (!navigator?.geolocation?.getCurrentPosition) {
                resolve(null);
                return;
            }
            // workaround for iOS bug
            GeoLocation.getCurrentPosition().then(pos => {
                if (!pos?.coords) {
                    resolve(null);
                    return;
                }

                let res: {[key: string]: any} = {};
                for (let key in pos.coords) {
                    if (pos.coords[key] != null) {
                        res[key] = pos.coords[key];
                    }
                }
                resolve(JSON.stringify(res));
            })
                .catch((err: unknown) => {
                    console.error(err);
                    resolve(null);
                })
            /*
            navigator.geolocation.getCurrentPosition(pos => 
            {
                if (!pos?.coords) {
                    resolve(null);
                    return;
                }

                let res = {};
                for (let key in pos.coords) {
                    if (pos.coords[key] != null) {
                        res[key] = pos.coords[key];
                    }
                }
                resolve(JSON.stringify(res));
            }, _ => resolve(null));
            */
        });
    }
}
